import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavigationItem.css';

const navigationItem = ( props ) => (
  <NavLink 
      className={props.classStyle}  
      activeClassName="NavigationItem-active"
      to={props.link}
      exact={props.exact}>
    {props.children}
  </NavLink>
);

export default navigationItem;
