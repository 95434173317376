import React from 'react';

import './NavigationItems.css';

import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = ( props ) => { 
  let attachedClasses = ["NavigationItem", "Close"];
  if (props.open) {
      attachedClasses = ["NavigationItem", "Open"];
  }
  return (
    <div className="nav-content" onClick={props.drawerToggleClicked}>
      <NavigationItem classStyle={attachedClasses.join(' ')} link="/who">Who WE are</NavigationItem>
      <NavigationItem classStyle={attachedClasses.join(' ')} link="/what">What WE do</NavigationItem>
      <NavigationItem classStyle={attachedClasses.join(' ')} link="/why">Why WE are right choice</NavigationItem>
      <NavigationItem classStyle={attachedClasses.join(' ')} link="/how">How WE cooperate</NavigationItem>
    </div>
  )
};

export default navigationItems;
