import React from 'react';

import './Page.css';
import Page from './Page';
import odkaz from "../../assets/images/webovy_odkaz.pdf";

const contact = (props) => (
  <Page
    header1="contact us"
    header2="compote, s.r.o."
    subtext={
      <div className="Page-text-contacts">
        Vysokoškolákov 1757/1<br />
        010 01 Žilina<br />
        Slovak Republic<br /><br />

        IČO: 47 622 997<br />
        DIČ: 2024007282<br />
        IČ DPH: SK2024007282<br /><br />
      <a className="Page-text-mailto" href="mailto:info@compote.eu">info@compote.eu</a><br /><br />
      <a className="Page-text-mailto" href={odkaz}>Rozvoj zručností zamestnancov spoločnosti COMPOTE</a>
      </div>
    }
  >
  <div />
  </Page>
);

export default contact;
