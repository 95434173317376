import React from 'react';

import './Page.css';
import Page from './Page';
import body from '../../assets/images/human body4.png'

const how = (props) => (
  <Page
    image={body}
    header1='customer'
    header2='partnership'
    subtext='on this path we are flexible and able to work in various engagements models, starting from simple staff augmentation through agile and hybrid models up to project with full responsibility.'>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>expectations and needs</h1>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>requirements maturity</h1>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>scope definition</h1>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>risk handling</h1>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>acceptance procedure</h1>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>payment model</h1>
  </Page>
);

export default how;
