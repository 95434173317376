import React from 'react';

import './Page.css';
import Page from './Page';
import body from '../../assets/images/human body2.png'

const what = (props) => (
  <Page
    image={body}
    header1='sw/hw'
    header2='engineering'
    subtext='company with a great team of engineers with long experiences in different technologies and industries. You can count on us in many areas of SW and HW development.'>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>early engagement</h1>
    <div className="Page-element">
      <ul>
        <li>Solution consulting</li>
        <li>Requirements elicitation</li>
        <li>Proof of concept (PoC)</li>
        <li>Prototyping and MVP Development</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>ux/ui design</h1>
    <div className="Page-element">
      <ul>
        <li>Wireframes & Prototypes</li>
        <li>User Experience Design</li>
        <li>User Interface Design</li>
        <li>Interaction Design</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>software development</h1>
    <div className="Page-element">
      <ul>
        <li>Requirement engineering</li>
        <li>Architecture and Design</li>
        <li>SW implementation</li>
        <li>Validation & Verification</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>hardware development</h1>
    <div className="Page-element">
      <ul>
        <li>HW Architecture</li>
        <li>Preliminary Production Design</li>
        <li>Design of Schematic Circuit Diagram</li>
        <li>Design of Printed Circuit Board</li>
        <li>Assembly, Evaluation, Programming and Debuging</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>project management</h1>
    <div className="Page-element">
      <ul>
        <li>Project tailoring</li>
        <li>Initiating and planning</li>
        <li>Executing, Monitoring and Controlling</li>
        <li>Project closing</li>
        <li>Different models (Agile, Traditional)</li>
      </ul>
    </div>
  </Page>
);

export default what;
