import React from 'react';

import './Page.css';

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      padding: 100,
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatePadding);
    this.updatePadding();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePadding);
  }

  updatePadding() {
    if (this.divHelper && this.divBlock) {
      const padding = (this.divHelper.clientHeight - this.divBlock.clientHeight) / 2;
      this.setState({
        padding,
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
  }

  render(){
    return (
      <>
        <div className="Page">
          {
            this.props.image &&
            <img src={this.props.image} alt="body" />
          }
          <div className="Page-text-block">
            <div className="Page-text-header1">{this.props.header1}</div>
            <div className="Page-text-header2">{this.props.header2}</div>
            <div className="Page-text-subtext">{this.props.subtext}</div>
          </div>
        </div>
        <div className="Page-container">
          <div className="Page-helper">
            <div
              className="Page-circle"
              ref={ (divHelper) => this.divHelper = divHelper}
            />
            <div
              style={{paddingTop: this.state.padding + 'px'}}
              className="Page-padding"/>
            <div
              ref={ (divBlock) => this.divBlock = divBlock}
              className="Page-block">
              {this.props.children}
            </div>  
          </div>
        </div>
      </>
    )
  }
}

export default Page;
