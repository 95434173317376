import React from 'react';

import './Page.css';
import Page from './Page';
import body from '../../assets/images/human body3.png'

const who = (props) => (
  <Page 
    image={body}
    header1='embrace'
    header2='technology'
    subtext='through innovation and expertise we deliver exceptional solutions. We are shaped and guided by our core values.'>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>humble</h1>
    <div className="Page-element">
      <ul>
        <li>We are confident in own strengths and aware of own weakness.</li>
        <li>We are constantly aiming for self-improvement.</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>respect stakeholder</h1>
    <div className="Page-element">
      <ul>  
        <li>We respect people and believe in transparency.</li>
        <li>Openly and honestly communicating, actively listening and challenging ideas to achieve the best  outcome.</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>take ownership</h1>
    <div className="Page-element">
      <ul>
        <li>We are honest and we take responsibility for ourselves, our team, our company and our actions.</li>
        <li>We are accountable for the results and take ownership for our mistakes.</li>
        <li>We are ready to make bold moves and decisions.</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>encourage teamwork</h1>
    <div className="Page-element">
      <ul>
        <li>We are one team with a commitment to one another, to a common goal and to a common vision.</li>
        <li>We complement and support one another.</li> 
        <li>We are clear about expectations, enjoy what we do and celebrate our achievements as a team.</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>efficient</h1>
    <div className="Page-element">
      <ul>
        <li>We use time to our advantage.</li>
        <li>We keep things simple, do the work that adds value and avoid wasting of energy or time.</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>deliver excellence</h1>
    <div className="Page-element">
      <ul>
        <li>We strive to be innovative and aim for continuous improvement.</li>
        <li>We accept challenges, manage risks and timely deliver our commitments.</li>
      </ul>
    </div>
  </Page>
);

export default who;
