import React, { Component } from 'react';

import './Layout.css';
import logo_symbol from '../../assets/images/logo_symbol.svg';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import NavigationItems from '../../components/Navigation/NavigationItems/NavigationItems';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState( { showSideDrawer: false } );
    }

    sideDrawerToggleHandler = () => {
        this.setState( ( prevState ) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        } );
    }

    render () {
      return (
        <>
          <div className="Background">
            <img src={logo_symbol} alt="Compote" />
          </div>
          <Toolbar />
          <nav>
              <NavigationItems open={this.state.showSideDrawer} drawerToggleClicked={this.sideDrawerToggleHandler}/>
          </nav>
          <main className="Content">
              {this.props.children}
          </main>
        </>
      )
    }
}

export default Layout;
