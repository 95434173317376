import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import './App.css';

import Layout from './hoc/Layout/Layout';
import Who from './components/pages/Who';
import What from './components/pages/What';
import Why from './components/pages/Why';
import How from './components/pages/How';
import Contact from './components/pages/Contact';

class App extends Component {

  state = {
    activeTarget: null
  };

  toggleHeader = (e) => {
    if (this.state.activeTarget) {
      this.state.activeTarget.classList.remove('Page-header-active');
    }
    e.target.classList.add('Page-header-active');
    this.setState({
      activeTarget: e.target
    });
  };

  render() {
    return (
      <div className="App">
        <Layout>
          <Switch>
            <Route name="who" path="/who" render={() => <Who toggleHeader={this.toggleHeader} />} />
            <Route path="/what" render={() => <What toggleHeader={this.toggleHeader} />} />
            <Route path="/why" render={() => <Why toggleHeader={this.toggleHeader} />} />
            <Route path="/how" render={() => <How toggleHeader={this.toggleHeader} />} />
            <Route path="/contact" render={() => <Contact toggleHeader={this.toggleHeader} />} />
            <Redirect from="/" to="who" />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default App;
