import React from 'react';
import { withRouter } from 'react-router-dom';

import './Toolbar.css';
import Logo from '../../Logo/Logo';
import Button from '../../UI/Button/Button';

const toolbar = (props) => (
  <header className="Toolbar">
    <Logo />
    <div />
    <Button
      btnType="Success"
      clicked={() => props.history.push('/contact')}
    >
      contact us
    </Button>
  </header>
);

export default withRouter(toolbar);
