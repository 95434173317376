import React from 'react';

import './Page.css';
import Page from './Page';
import body from '../../assets/images/human body1.png'

const why = (props) => (
  <Page
    image={body}
    header1='strong'
    header2='network'
    subtext='of engineers, partners and global presence, makes us perfect candidate to choose.'>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>knowledge & experience</h1>
    <div className="Page-element">
      <ul>
        <li>Broad technology and service portfolio</li>
        <li>Long term experience in Global SW service companies</li>
        <li>Strong network of engineers and IT companies.</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>customer orientation</h1>
    <div className="Page-element">
      <ul>
        <li>Close cooperation with our clients from Day 1</li>
        <li>We listen to our clients and propose solutions</li> 
        <li>Building trust and long term cooperation</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>effectiveness & flexibility</h1>
    <div className="Page-element">
      <ul>
        <li>Time and cost efficiency</li>
        <li>Flexibility in team sizing</li>
        <li>Process tailoring</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>processes</h1>
    <div className="Page-element">
      <ul>
        <li>Strong Project Management</li>
        <li>Flexible Software Development</li>
        <li>Quality Management System</li>
      </ul>
    </div>
    <h1 className="Page-header" onMouseEnter={props.toggleHeader}>reliability</h1>
    <div className="Page-element">
      <ul>
        <li>Our solutions meet client expectations</li>
        <li>Reliable software and hardware</li>
        <li>On time delivery within budget</li>
      </ul>      
    </div>
  </Page>
);

export default why;
