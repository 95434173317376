import React from 'react';
import { NavLink } from 'react-router-dom';

import logo_symbol from '../../assets/images/logo_symbol_small.svg';
import logo_compote from '../../assets/images/logo_compote.svg';
import './Logo.css';

const logo = (props) => (
  <div className="Logo">
    <NavLink to="/">
      <img className="Logo-symbol" src={logo_symbol} alt="Compote" />
      <img className="Logo-text" src={logo_compote} alt="Compote" />
    </NavLink>
  </div>
);

export default logo;
